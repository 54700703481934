<template>
  <div class="dash-title">
    <div class="title">Riders</div>
    <div class="filter">
      <div>
        <input type="date" />
      </div>

      <div>
        <select class="form-select" aria-label="Default select example">
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
    </div>
  </div>
  <!-- items -->
  <div class="items-container">
    <table style="width: 100%">
      <tr>
        <td id="header">Rider</td>
        <td id="header">Area</td>
        <td id="header">Gender</td>
        <td id="header">Distance</td>
        <td id="header">Date</td>
        <!-- <td id="header">Action</td> -->
      </tr>
      <tr class="items-label">
        <td>
          <div class="f-details">
            <img :src="food" alt="" />
            <div class="food_type">
              <div class="food-name">Michael Okonkwo</div>
              <div class="combo">Rider</div>
            </div>
          </div>
        </td>
        <td>
          <div>
            <div class="food-name">Utako</div>
          </div>
        </td>
        <td>
          <div class="food-name">Male</div>
        </td>
        <td>
          <div class="food-name">22KM</div>
        </td>
        <td>
          <div class="food-name">09/09/2021</div>
        </td>
        <!-- <td>
          <div class="button">
            <div class="view"><button>View</button></div>
            <div class="accept"><button>Accept</button></div>
            <div class="cancel"><img :src="cancel" alt="" /></div>
          </div>
        </td> -->
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      food: require("../../../../assets/food_img.png"),
      cancel: require("../../../../assets/cancel.png"),
    };
  },
};
</script>

<style scoped>
.title {
  color: rgba(4, 3, 15, 1);
  font-weight: 600;
  font-size: 15px 20px;
}

.qty {
  color: #43424c;
  padding-left: 10px;
}

#header {
  font-size: 13px;
  color: rgba(67, 66, 76, 1);
}

.cancel img {
  padding: 5px;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 50%;
}

.view button {
  border: 1px solid rgba(133, 77, 39, 1);
  background-color: #fff;
  margin-right: 10px;
  color: rgba(133, 77, 39, 1);
  border-radius: 5px;
  padding: 3px 9px;
  /* font-size:20px; */
}

.accept button {
  border: none;
  background-color: rgba(133, 77, 39, 1);
  margin-right: 10px;
  color: #fff;
  border-radius: 5px;
  padding: 3px 9px;
  /* font-size:20px; */
}

#food-transact {
  display: flex;
  justify-content: space-between;
}

.combo {
  color: rgba(67, 66, 76, 1);
  font-size: 10px;
}

.food-name {
  color: rgba(67, 66, 76, 1);
  font-size: 12px;
  font-weight: 600;
}

.f-details {
  display: flex;
  padding: 10px;
}

.button {
  display: flex;
}

.food_type {
  margin-left: 10px;
}

.items-label {
  background: #ffffff;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.order {
  margin-right: 10%;
}

.filter {
  display: flex;
}

.items-container {
  margin: 0px 45px;
  margin-top: 20px;
}

.other-label {
  display: flex;
  justify-content: space-around;
}

input {
  outline: none;
  border: none;
  box-shadow: none;
  color: black;
  padding: 2px 15px;
  margin-right: 10px;
  /* background: #FFFFFF; */
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.header-label {
  display: flex;
  justify-content: space-around;
  color: rgba(67, 66, 76, 1);
  font-size: 15px;
  margin-top: 3%;
}

.form-select {
  outline: none;
  border: none;
  box-shadow: none;
  color: black;
  padding: 2px 30px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.dash-title {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
  margin-left: 5%;
}
</style>
